import { ButtonFactory } from '../core/ButtonFactory';
import { ButtonIcon } from '../core/IconPosition';
import { styleFactory } from '../core/utils';
import {
  ActionButtonProps,
  ButtonStyle,
  IconTypes,
  LinkButtonProps,
} from '../types';
import styles from './iconButton.module.scss';

type IconButtonProps =
  | {
      icon: IconTypes;
      style: {
        shape: 'circle' | 'square';
        ghost?: boolean;
      } & ButtonStyle;
    } & (ActionButtonProps | LinkButtonProps);

export const IconButton = ({
  icon,
  style: variant,
  ...props
}: IconButtonProps) => {
  const styleBuilder = styleFactory(styles, variant);

  styleBuilder.add([styles.iconButton]);
  styleBuilder.add([styles[variant.shape]]);

  if (variant.ghost) {
    styleBuilder.add([styles.ghost]);
  }

  const style = styleBuilder.get();

  const Component = ButtonFactory(props);

  return (
    <Component className={style} {...props}>
      <ButtonIcon icon={icon} className={styles.icon} />
    </Component>
  );
};
