import styles from './illustration.module.scss';
import Bike from './svgs/bike.svg';
import Bitcoin from './svgs/bitcoin.svg';
import Coffee from './svgs/coffee.svg';
import Community from './svgs/community.svg';
import Crown from './svgs/crown.svg';
import Holiday from './svgs/holiday.svg';
import Laptop from './svgs/laptop.svg';
import MountainClimb from './svgs/mountain-climb.svg';
import MountainTop from './svgs/mountain-top.svg';
import Pacman from './svgs/pacman.svg';
import Party from './svgs/party.svg';
import PiggyBank from './svgs/piggy-bank.svg';
import Resume from './svgs/resume.svg';
import RollWheel from './svgs/roll-wheel.svg';
import Share from './svgs/share.svg';
import UploadDocument from './svgs/upload-document.svg';
import WorkHome from './svgs/work-home.svg';
import Zigzag from './svgs/zigzag.svg';

export const Illustrations = {
  Bike,
  Bitcoin,
  Coffee,
  Community,
  Crown,
  Holiday,
  Laptop,
  MountainClimb,
  MountainTop,
  Pacman,
  Party,
  PiggyBank,
  Resume,
  RollWheel,
  Share,
  WorkHome,
  Zigzag,
  UploadDocument,
};

export interface IllustrationProps {
  name: keyof typeof Illustrations;
  className?: string;
}

export const Illustration = ({ name, className }: IllustrationProps) => {
  const Component = Illustrations[name];
  return <Component className={`${styles.illustration} ${className ?? ''}`} />;
};
