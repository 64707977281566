import { Typography } from 'components/typography';

import { ButtonFactory } from '../core/ButtonFactory';
import { IconPosition } from '../core/IconPosition';
import { LabelUnderline } from '../core/LabelUnderline';
import { styleFactory } from '../core/utils';
import { ButtonProps, ButtonStyle, ButtonStyleTypes } from '../types';
import styles from './linkButton.module.scss';

type ExcludeTertiaryType = Exclude<ButtonStyleTypes, 'tertiary'>;

const textVariantMap: Record<
  ExcludeTertiaryType,
  'label-reg' | 'label-compact'
> = {
  primary: 'label-reg',
  secondary: 'label-compact',
};

type LinkButtonProps = ButtonProps & {
  style: ButtonStyle & {
    type: ExcludeTertiaryType;
  };
};

export const LinkButton = ({
  icon,
  style,
  children,
  ...props
}: LinkButtonProps) => {
  const classNames = styleFactory(styles, style).add([styles.linkButton]).get();
  const textColor = style.inverted ? 'white' : undefined;

  const Component = ButtonFactory(props);

  return (
    <Component href={props.href ?? ''} className={classNames} {...props}>
      <IconPosition icons={icon}>
        <LabelUnderline inverted={style.inverted}>
          <Typography
            variant={textVariantMap[style.type]}
            tag="span"
            color={textColor}
          >
            {children}
          </Typography>
        </LabelUnderline>
      </IconPosition>
    </Component>
  );
};
