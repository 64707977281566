import { ButtonFactory } from '../core/ButtonFactory';
import { ButtonInteractionProps } from '../types';
import styles from './simpleButton.module.scss';

type SimpleButtonProps = ButtonInteractionProps & {
  children: React.ReactNode;
};

export const SimpleButton = ({ children, ...props }: SimpleButtonProps) => {
  const Component = ButtonFactory(props);

  return (
    <Component className={styles.simpleButton} {...props}>
      {children}
    </Component>
  );
};
