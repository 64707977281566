import styles from './labelUnderline.module.scss';

interface LabelUnderlineProps {
  inverted?: boolean;
  children: React.ReactNode;
}

export const LabelUnderline = ({ inverted, children }: LabelUnderlineProps) => {
  const invertedStyle = inverted ? styles['inverted'] : '';
  const style = [styles.label, invertedStyle].join(' ');

  return <div className={style}>{children}</div>;
};
