import { Button } from '../components/Button';
import { ActionButtonProps, ButtonProps, LinkButtonProps } from '../types';

type PrimaryButtonProps = Omit<Partial<ButtonProps>, 'icon' | 'style'> &
  (ActionButtonProps | LinkButtonProps);

export const PrimaryButton = ({ children, ...props }: PrimaryButtonProps) => (
  <Button
    style={{ type: 'primary' }}
    icon={{ trailing: 'ArrowRight' }}
    {...props}
  >
    {children}
  </Button>
);
