import Link from 'next/link';

import { ActionButtonProps, LinkButtonProps } from '../types';

type Props = Record<string, unknown>;

const InternalLink = (props: Props & { href: string }) => (
  <Link {...props} href={props.href} />
);

const ExternalLink = (props: Props) => (
  <a target="_blank" rel="noreferrer nofollow" {...props} />
);

const InternalButton = (props: Props) => <button {...props} />;

export const ButtonFactory = ({
  href,
  onClick,
}: ActionButtonProps | LinkButtonProps) => {
  if (href) {
    const Link = href.includes('//')
      ? (props: Props) => <ExternalLink {...props} href={href} />
      : (props: Props) => <InternalLink {...props} href={href} />;
    return Link;
  } else {
    const Button = (props: Props) => (
      <InternalButton {...props} onClick={onClick} />
    );
    return Button;
  }
};
