import { ReactNode } from 'react';

import styles from './container.module.scss';

export interface ContainerProps {
  className?: string;
  id?: string;
  children: ReactNode;
  size: 'narrow' | 'default' | 'wide' | 'xWide';
  layoutBlock?:
    | 'cast'
    | 'list'
    | 'jobs'
    | 'tiles'
    | 'media'
    | 'illustration'
    | 'centered'
    | 'left';
}

export const Container = ({
  className,
  children,
  size,
  layoutBlock,
  id,
}: ContainerProps) => {
  return (
    <div
      className={`${styles.container} ${styles[size]} ${
        layoutBlock ? styles[layoutBlock] : ''
      } ${className ?? ''}`}
      id={id}
    >
      {children}
    </div>
  );
};
