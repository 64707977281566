import { ButtonStyle } from '../types';

export const styleFactory = (
  styles: { [key: string]: string },
  variant: ButtonStyle
) => {
  const invertedStyle = variant.inverted ? styles['inverted'] : '';
  const typeStyle = styles[variant.type] || '';
  const disabledStyle = variant.disabled ? styles['disabled'] : '';
  let s: string[] = [invertedStyle, typeStyle, disabledStyle];

  const add = (addedStyles: string[] = []) => {
    s = [...addedStyles, ...s];
    return { add, get };
  };

  const get = () => {
    return s.join(' ');
  };

  return { add, get };
};
