import { Icon } from '@/components/internalComponents/icon';

import { ButtonIconProps as IconProps, IconTypes } from '../types';
import styles from './IconPosition.module.scss';

interface IconPositionProps {
  icons?: IconProps;
  children: React.ReactNode;
}

type ButtonIconProps = {
  icon: IconTypes;
  className?: string;
};

export const ButtonIcon = ({ icon, className = '' }: ButtonIconProps) => (
  <Icon className={[styles.icon, className].join(' ')} name={icon} />
);

export const IconPosition = ({ icons, children }: IconPositionProps) =>
  icons ? (
    <>
      {icons.leading && <ButtonIcon icon={icons.leading} />}
      {children}
      {icons.trailing && <ButtonIcon icon={icons.trailing} />}
    </>
  ) : (
    <>{children}</>
  );
